
document.addEventListener('DOMContentLoaded', function () {
    IntiaroCookieManager.initialize();
});

var IntiaroCookieManager = (function() {
    "use strict";

    var cookieName = 'intiaroCookieConsent'
    var banner;
    var acceptAndCloseButton;
    const privacyPolicyUrl = window.location.origin + '/privacy';

    function initialize() {

        if (!_cookieExists(cookieName)) {
          _createCookieBanner();
        }
    }

    function _cookieExists(name) {
        return (document.cookie.split('; ').find(row => row.startsWith(name)))
    }

    function _getDomainName(hostName)
    {
        return hostName.substring(hostName.lastIndexOf(".", hostName.lastIndexOf(".") - 1) + 1);
    }

    function _setCookie(days) {
        const domain = _getDomainName(window.location.hostname);

        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        const expires = date.toGMTString();

        document.cookie = `${cookieName}=true; path=/; domain=${domain}; expires=${expires}`;
    }

    function _createCookieBanner() {

        // Banner styles in assets/css/cookies-banner.css

        // Create banner
        var cookiesIcon = `<img class="intiaro-cookie-banner-icon" src="assets/images/icons/cookie-bite.svg"></img>`;
        var checkIcon = `<img class="intiaro-cookie-banner-accept-and-close-icon" src="assets/images/icons/check-icon.svg"></img>`;
        var bannerTitle = 'We use cookies';
        var bannerButtonText = 'Accept and close';
        var bannerStatement = `We use cookies and other tracking technologies to improve your browsing experience on our website, to show you personalized content and targeted ads, to analyze our website traffic, and to understand where our visitors are coming from. By browsing our website, you consent to our use of cookies and other tracking technologies. Find out more on the <a href="${privacyPolicyUrl}" target="_blank" class="intiaro-cookie-banner-consent">Privacy policy</a> page.`;

        banner = document.createElement('div');
        banner.classList.add("intiaro-cookie-banner");
        banner.innerHTML =
        '<div class="intiaro-cookie-banner-content">' +
        `<div class="intiaro-cookie-banner-title">${cookiesIcon} <span class="intiaro-cookie-banner-title-bold">${bannerTitle}</span></div>` +
        '<div class="intiaro-cookie-banner-flexbox">' +
        `<p class="intiaro-cookie-banner-text" id="intiaro-cookie-banner-text">${bannerStatement}</p>` +
        `<button class="intiaro-cookie-banner-accept-and-close" id="intiaro-cookie-banner-accept-and-close-button">${bannerButtonText} ${checkIcon}</button>` +
        '</div>' +
        '</div>';

        document.body.append(banner);

        // Add accept and close button listener
        acceptAndCloseButton = document.getElementById("intiaro-cookie-banner-accept-and-close-button");
        acceptAndCloseButton.addEventListener("click", _acceptButtonClicked);
    }

    function _acceptButtonClicked() {
        _setCookie(9999);
        _removeCookieBanner();
    }

    function _removeCookieBanner() {
        banner.remove();
    }

    return {
        initialize: initialize,
    };
})();
