var IntiaroSystemInfo = class IntiaroSystemInfo {

    constructor() {
        if (!!IntiaroSystemInfo.instance) {
            return IntiaroSystemInfo.instance;
        }

        IntiaroSystemInfo.instance = this;

        this.analyzeEnvironment();

        return this;
    }

    analyzeEnvironment()
    {
        //https://github.com/darcyclarke/Detect.js/

        var ua = detect.parse(navigator.userAgent);

        this.appName = "DesignerBoard3D";
        this.device = ua.device.family;
        this.operatingSystem = ua.os.family;
        this.browser = ua.browser.family;
    }
};
